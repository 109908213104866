.text-align-center {
  text-align: center;
}

html, body, #root, #root > .container {
  height: 100%;
  width: 100%;
}
body {
  background: url("/public/bg.png");
  background-repeat: no-repeat;
}
/* */
.slogan-container {
  height: 150px;
  z-index: 99;
  position: relative;
}
.slogan-container > div {
  width: 100%;
  max-height: 100%;
}
.slogan {
  padding-top: 10px;
  max-height: 150px;
  width: auto;
}
/* */
.panel-container {
  height: 80%;
  padding-top: 20px;
  /* padding-bottom: 3%; */
}
.panel-container > div {
  /* height: 100%; */
  /* width: 100%; */
}
/* */
.image-area {
  width: 100%;
  height: 65%;
  max-height: 65%;
  margin: 0 auto !important;
}
.image-container {
  background: url('/public/sky.png');
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  padding: 0px;
}
.border-image {
  max-width: 124% !important;
  width: 124% !important;
  height: 124%;
  left: -12%;
  top: -12%;
  position: absolute;
  z-index: 90;
}
.img-preview, .webcam-view {
  width: 60%;
  height: auto;
  max-height: 65%;
  object-fit: fill;
  position: absolute;
  left: 20%;
  top: 12%;
  z-index: 91;
}
.img-preview {
  top: 14%;
}
.button-overlay {
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.button-overlay-camera {
  z-index: 99;
  text-align: center;
}
.btn-upload-image {
  background-color: inherit;
  padding: 10px;
  border: none;
  font-size: 18px;
  color: green;
  cursor: pointer;
  z-index: 1000;
  margin: 5%;
}
/* */
.button-container {
  height: 20%;
  margin: 0 auto !important;
  z-index: 99;
  position: relative;
  padding-left: 7.5%;
  /* padding-right: 7.5%; */
}
.button-container > div {
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
}
.btn-continue {
  background-color: rgb(0, 152, 253);
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  width: 100%;
  margin-top: 10px;
}
.btn-slogan {
  background-color: rgb(255, 255, 255);
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  color: rgb(0, 152, 253);
  width: 100%;
  margin-top: 10px;
}
.text-caption {
  width: 80% !important;
}
.text-caption-full {
  width: 100% !important;
  margin-top: 10px;
}
.text-caption-btn {
  width: 20% !important;
  border-radius: 0px !important;
}
.text-caution {
  color: white;
  background: rgba(0, 0, 0, .5);
}

/* .center {
  display: block;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}
.userimage {
  margin-top: 20px;
  height: 400px;
  width: 400px;
  background: url("/public/sky.png");
  background-repeat: no-repeat;
}
#upload-image {
  visibility: hidden;
}
.btn-upload-image {
  background-color: inherit;
  padding: 10px;
  border: none;
  font-size: 18px;
  color: green;
  cursor: pointer;
  z-index: 1000;
}
.container-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  margin-top: 50px;
  max-width: 768px;
}
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-image {
  position: absolute;
  top: -69px;
  left: -78px;
}
.hoa-1 {
  position: absolute;
  top: 50%;
  left: 0;
}
.hoa-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}


.button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.button-overlay button {
  display: block;
  margin: 10px auto;
  width: 200px;
}
.img-main-content {
  position: relative;
}
.border-img {
  position: absolute;
  z-index: 100;
  bottom: -20%;
  left: -22%;
}

.user-image-btn-area {
  margin: 0px;
}
.btn-continue {
  background-color: rgb(0, 152, 253);
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  width: 100%;
  margin-top: 10px;
}
.btn-slogan {
  background-color: rgb(255, 255, 255);
  border: none;
  height: 45px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  color: rgb(0, 152, 253);
  width: 100%;
  margin-top: 10px;
}
.text-caption {
  width: 100%;
  margin-top: 10px;
}
.text-align-center {
  text-align: center;
} */